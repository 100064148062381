import './Home.css'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import axios from '../api/axios';
const Home = () => {
  const [subscribeEmail, setSubscribeEmail] = useState({
    subscribeEmail: ''
  });

const onChange = (e) => {
  setSubscribeEmail({...subscribeEmail, [e.target.name] : e.target.value});
}

const onSubmit = (e) => {
  e.preventDefault();
  if(subscribeEmail.subscribeEmail != '') {
    axios.post('/subscribeEmail', subscribeEmail).then(res => {
        if(res.data.status == 'error'){
            alert('Unkoun error. Try again.');
        }
        else {
            alert('Thank you! We have received your email.')
        }
       
    }).catch(err => {
        alert('Unkoun error. Try again.');
    })
    setSubscribeEmail({...subscribeEmail, subscribeEmail : ''} )

}
else {
alert('Enter correct Data!');
}
}

return (
    <div className='home-container'>
     <div className="welcom">
       <h2>Our mission to provide innovative, quality and sustainable ICT solutions.</h2>
       <div className='contact-us'>
<h3>To work with us <Link to={'/contact'}>Contact Us</Link></h3>
</div>
     </div>

<div className='goal'>
<div className='vision'>
    <h4>Vision</h4>
    <p>A dynamic world-class ICT Solutions Centre </p>
    <p> powering creativity and innovation </p>
    <p> in the Ethiopia</p>
</div>
<div className='mission'>
    <h4>Mission</h4>
    <p>To provide innovative, quality </p>
    <p> and sustainable ICT solutions </p>
    <p> and services that meet the aspiration </p>
    <p>of the Ethiopia in the learning,teaching,</p>
    <p>research and development</p>
</div>
</div>

<div className='focus-area-div'>
<h3 className='focus-area'>Focus Area</h3>
     <div className='tech-area'>
        
<div className='dev'>
<img src="./assets/images/dev.png" height={100} alt="" srcset="" />
<p>Software Design & Development</p>
</div>
<div className='dev'>
<img src="./assets/images/training.png" height={100} alt="" srcset="" />
<p>ICT Training</p>
</div>
<div className='dev'>
<img src="./assets/images/consoltancy.jpg" height={100} alt="" srcset="" />
<p>Consultancy</p>
</div>
<div className='dev'>
    <img src="./assets/images/partner.jfif" height={100} alt="" srcset="" />
<p>Partnership</p>
</div>
     </div>
</div>

<div className='achievement'>
<h3>Achievement</h3>
<div className='achiement-list'>
<div className='achv'>
    <img src="./assets/images/ecommerce.webp" alt="" srcset="" />
    <h4>Ecommerce</h4>
</div>
<div className='achv'>
    <img src="./assets/images/advert.jfif" alt="" srcset="" />
    <h4>Advertisement Platform</h4>
</div>
<div className='achv'>
    <img src="./assets/images/video-conference.jpg" alt="" srcset="" />
    <h4>Video Conference Platform</h4>
</div>
<div className='achv'>
    <img src="./assets/images/human-resources.jpg" alt="" srcset="" />
    <h4>Human Resource Management</h4>
</div>
</div>
</div>

     <div className='staff'>
        <h3>Team Members</h3>
        <div className='staffs'>
        <div className='person'>
  <img src="./assets/images/yaschalew.jpg" height={100} alt="" srcset="" />
    <h4>Yaschalew Erkihun</h4>
    <p>Senior Software Engineer</p>
    <p>Full stack Developer</p>
  </div>
  <div className='person'>
  <img src="./assets/images/danial.jpg" height={100} alt="" srcset="" />
    <h4>Danial Mulugeta</h4>
    <p>Senior Software Engineer</p>
    <p>Full stack Developer</p>
  </div>
  <div className='person'>
  <img src="./assets/images/chilen.jpg" height={100} alt="" srcset="" />
    <h4>Chilenew Belachew</h4>
    <p>Sinior Quality Assurance Engineer</p>
  </div>
  <div className='person'>
  <img src="./assets/images/girmay.jpg" height={100} alt="" srcset="" />
    <h4>Girmay G/medhine</h4>
    <p>Sinior Quality Assurance Engineer</p>
  </div>
  <div className='person'>
  <img src="./assets/images/yosief.jpg" height={100} alt="" srcset="" />
    <h4>Yosef Kitaw Damenu</h4>
    <p>Junior Cyber Security Officer</p>
  </div>
  <div className='person'>
  <img src="./assets/images/yordanos.jpg" height={100} alt="" srcset="" />
    <h4>Yordanos Chekol</h4>
    <p>Senior Software Engineer</p>
    <p>Full stack Developer</p>
  </div>
  </div>
     </div>

<div className='subscribe'>
    <h3>Subscribe for news.</h3>
<form onSubmit={onSubmit}>
    <input type="email" name='subscribeEmail' value={subscribeEmail.subscribeEmail}  onChange={(e) => onChange(e)} placeholder='enter your email'/>
    <button type="submit">Submit</button>
</form>
</div>

    </div>
);
}

export default Home;