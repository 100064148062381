
import {FaMobileAlt, FaFacebookF} from 'react-icons/fa';
import {MdOutlineMail} from 'react-icons/md'
import { FiSend } from 'react-icons/fi'
import './About.css'
const About = () => {
    return (
        <div  className='about-container'>
            <div className="about">
            <h3>About</h3>
                <h4>
                    Avola ICT Solutions is virtual team which is started to work since 2017. It relay on virtual team which aims to collect professional </h4>
                    <h4>from different locations & orginations</h4>
                <h4>The final goal of Avola ICT Solutions team is create strong problem solving ICT center </h4>
                 <h4>by devoting research and development around the problem with emerging technology</h4>
            </div>
            <div className='other'>
               <div className='team-member'>
               <h3>Team Members</h3>
               <div className='member'>
               <div>
               <img src="./assets/images/yaschalew.jpg" height={130}  alt="" srcset="" />
               <h5>Yaschalew Erkihun</h5>
               <p>Founder of The Team</p>
                </div>
               <div className='detail'>
                <p>Bsc in Software Engineering from Addis Ababa Scince & Technology University</p>
                <p>Senior Software Engineer</p>
                <p>Full stack Developer</p>
                <p>Works at Excellerent Delivery Center</p>
                <br />
               <p><a className='email'href="tel:+251 929022432"> <FaMobileAlt /> +251 929022432</a></p>
              <p> <a className='email' href="mailto:yaschalewerkihun6@gmail.com">
                <MdOutlineMail />
                yaschalewerkihun6@gmail.com</a></p>
           <p><FiSend /> @yaschalew </p>
           <p> <FaFacebookF /> Yaschalew Erkihun </p>
               </div>
               </div>

               <div className='member'>
               <div>
               <img src="./assets/images/girmay.jpg" height={130}  alt="" srcset="" />
               <h5>Girmay G/medhine</h5>
               <p>Coordinator of The Team</p>
                </div>
               <div className='detail'>
                <p>Bsc in Software Engineering from Addis Ababa Scince & Technology University</p>
                <p>Quality Assurance Engineer</p>
                <br />
               <p><a className='email'href="tel:+251 917598254"> <FaMobileAlt /> +251 917598254</a></p>
              <p> <a className='email' href="mailto:girmaymedhine@gmail.com">
                <MdOutlineMail />
                girmaymedhine@gmail.com</a></p>
           <p><FiSend /> @girmaye_g </p>
           <p> <FaFacebookF /> Girmay G/medhine </p>
               </div>
               </div>

               </div>
            </div>
        </div>
    );
}

export default About;