import {Outlet, Link} from 'react-router-dom';
import {FaMobileAlt, FaBars} from 'react-icons/fa';
import {MdOutlineMail} from 'react-icons/md'

import './Layout.css'
const Layout = () => {
    return (
 <div className='container'>
 <div className='content'>
 <div className='layout'>
  <div className='hearder'>
<div className='company'>
<h2>Avola ICT Solutions</h2>
</div>
<div className='contact'>
<a href="tel:+251929022432">
   <FaMobileAlt /> 
   +251929022432</a>
 <a href="mailto:yaschalewerkihun6@gmail.com"> 
 <MdOutlineMail />
 <span className='email'>yaschalewerkihun6@gmail.com</span>
 </a>
</div>
  </div>
   <nav className='nav'>
   <div className='logo'>
      <Link to = {''} >
        <img src="../assets/images/logo.png" height={50} alt="" srcset="" />
      </Link>
     </div>

<label for="check" className='check-labl'>
   <FaBars />
</label>
<input className='check-box' id='check' type="checkbox" />
     <ul>
      <li>
      <Link to={''}>Home</Link>
      </li>
      <li>
      <Link to={'service'}>Service</Link>
      </li>
      <li>
      <Link to={'about'}>About</Link>
      </li>
      <li>
      <Link to={'contact'}>Contact</Link>
      </li>
     </ul>
   </nav>
   </div>
    <div className= 'outlet'>
    <Outlet  />
    </div>
    <footer>
<p>Powerd By Yaschalew Erkihun</p>
    </footer>
 </div>
 </div>
    );
}

export default Layout;