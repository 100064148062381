
import './Service.css';
import { Link } from 'react-router-dom';
const Service = () => {
    return (
 <div className='service-container'>
    <div className='service-img'>
<img src="./assets/images/service.jpg" height={300}  alt="" srcset="" />
<img src="./assets/images/service.jpg" height={300}  alt="" srcset="" />
    </div>
  <div className='focus-area-div'>
<h3 className='focus-area'>Services</h3>
     <div className='tech-area'>
        
<div className='dev'>
<img src="./assets/images/dev.png" height={100} alt="" srcset="" />
<h4>Software Design & Development</h4>
<div className='service'>
<p>Devoting on research and development </p>
<p>around the problem with emerging technology</p>
</div>
</div>
<div className='dev'>
<img src="./assets/images/training.png" height={100} alt="" srcset="" />
<h4>ICT Training</h4>
<div className='service'>
<p>We provide ICT related training </p>
<p>From MS office to software development</p>
</div>
</div>
<div className='dev'>
<img src="./assets/images/consoltancy.jpg" height={100} alt="" srcset="" />
<h4>Consultancy</h4>
<div className='service'>
<p>We provide ICT related consultancy </p>
<p>for any individual or enterprise</p>
</div>
</div>
<div className='dev'>
    <img src="./assets/images/partner.jfif" height={100} alt="" srcset="" />
<h4>Partnership</h4>
<div className='service'>
<p>We have suitable platform to collaborate</p>
<p>with any enterprises which have related</p>
<p>objective with us.</p>
</div>
</div>
     </div>
</div>

<div className='contact-us'>
<h3>To get the above service <Link to={'/contact'}>Contact Us</Link></h3>
</div>

 </div>
    );
}

export default Service;