
import './Contact.css'
import axios from '../api/axios';
import {FaMobileAlt, FaFacebookF} from 'react-icons/fa';
import {MdOutlineMail} from 'react-icons/md'
import { FiSend } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go'
import { useState } from 'react';
const Contact = () => {
    const [contactForm, setContactForm] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const onChange = (e) => {
        setContactForm({...contactForm, [e.target.name] : e.target.value} )
    }

    const onSubmit = (e) => {
e.preventDefault();
if(contactForm.name != '' && contactForm.phone != '' && contactForm.email != '' && contactForm.message != '') {
    axios.post('/contact', contactForm).then(res => {
        if(res.data.status == 'error'){
            setError('Unkoun error. Try again.');
            setSuccess('')
        }
        else {
            setError('');
            setSuccess('Thank you! We have received your message.')
        }
       
    }).catch(err => {
        setError('Unkoun error. Try again.');
            setSuccess('')
    })
    setContactForm({...contactForm, message : ''} )

}
else {
setError('Enter correct Data!');
setSuccess('')
}

    }

return (
    <div className="contact-container">
        <h3>Contact US</h3>
     <div className='contact-item'>
     <div className='our-contact'>
        <h4>Our Contacts</h4>
        <div className='address'>
            <h4><GoLocation className='icon' /> Address: </h4>
            <p>Addis Ababa, Ethiopia</p>
        </div>
        <p><a className='email'href="tel:+251 929022432"> <FaMobileAlt className='icon' /> +251 929022432</a></p>
              <p> <a className='email' href="mailto:yaschalewerkihun6@gmail.com">
                <MdOutlineMail className='icon' />
                yaschalewerkihun6@gmail.com</a></p>
           <p><FiSend className='icon' /> @yaschalew </p>
           <p> <FaFacebookF className='icon' /> Yaschalew Erkihun </p>
        </div>
        <div className='direct-contact'>
          <h4>Direct Message</h4>
          <form onSubmit={onSubmit}>
            <label className='err'>  {error} </label>
            <label className='success'>  {success} </label>
            <div className='form-item'>
             <label>Name</label>
             <input type="text" name='name' onChange={(e) => onChange(e)} placeholder='enter name...' />
            </div>
            <div className='form-item'>
             <label>Phone</label>
             <input type='tel' name='phone' onChange={(e) => onChange(e)} placeholder='enter phone...'/>
            </div>
            <div className='form-item'>
             <label>Email</label>
             <input type="email" name='email' onChange={(e) => onChange(e)} placeholder='enter email...' />
            </div>
            <div className='form-item'>
             <label>Message</label>
             <textarea  name='message' value={contactForm.message} onChange={(e) => onChange(e)} placeholder='enter message...' id="" cols="30" rows="10"></textarea>
            </div>
           <div className='submit'>
           <button type='submit'>Submit</button>
           </div>
          </form>
        </div>
     </div>
    </div>
);
}

export default Contact;