import {BrowserRouter, Routes, Route}  from 'react-router-dom'
import Layout from './components/Layout';
import Home from './components/Home'
import About from './components/About';
import Contact from './components/Contact'
import Service from './components/Service';

function App() {
  return ( 
   <BrowserRouter>
   <Routes>
    <Route path='' element = { <Layout />    }>
      <Route path='/' element = { <Home /> }/>
      <Route path='/about' element = { <About />} />
      <Route path='/service' element = { <Service /> }/>
      <Route path='/contact' element = { <Contact />} />
    </Route>
   </Routes>
   </BrowserRouter>
   );
}

export default App;